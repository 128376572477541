import { Box, Typography, type SxProps } from '@mui/material'
import { addAmounts, gt, multiplyByNumber, substractAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import { AmountText, Icon, borderRadius, colors, spacing } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'

import type { AggregatedQuote } from '@orus.eu/dimensions'
import { alertRoleConfigurations } from './alert-banner/alert-role-configuration'

type BackofficeQuoteCardProps = {
  quote: AggregatedQuote
  /**
   * Will be displayed in the top right corner if provided
   */
  extraIcons?: ReactNode
}

/**
 * This card displays the technical details of a quote in the backoffice
 */
export function BackofficeQuoteCard({ quote, extraIcons }: BackofficeQuoteCardProps): JSX.Element {
  const yearlyPartnerManagementPremium =
    quote.paymentRecurrence === 'yearly'
      ? quote.yearlyPartnerManagementPremium
      : quote.monthlyPartnerManagementPremium
        ? multiplyByNumber(quote.monthlyPartnerManagementPremium, 12)
        : undefined

  return (
    <Box sx={cardSx}>
      <Icon icon="calculator-regular" color={alertRoleConfigurations.informational.secondaryColor} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1Semibold">Informations du devis</Typography>
          {extraIcons ? <Box>{extraIcons}</Box> : <></>}
        </Box>

        <Box sx={{ display: 'grid', gap: spacing[30], gridTemplateColumns: 'auto 1fr' }}>
          <QuoteCardSubtitle text="Montants annuels" />

          <QuoteCardAmountRow title="Prime de base annuelle" amount={quote.yearlyBasePremium} />
          {yearlyPartnerManagementPremium && gt(yearlyPartnerManagementPremium, zeroAmount) ? (
            <QuoteCardAmountRow title="Frais de gestion annuels" amount={yearlyPartnerManagementPremium} />
          ) : null}

          <QuoteCardSeparator />

          {quote.terrorismTax && gt(quote.terrorismTax, zeroAmount) ? (
            <>
              <QuoteCardAmountRow
                title="Taxes d'assurance hors attentat annuelle"
                amount={substractAmounts(quote.yearlyTaxes, quote.terrorismTax)}
              />
              <QuoteCardAmountRow title="Taxe attentat annuelle" prefix="+ " amount={quote.terrorismTax} />
            </>
          ) : (
            <></>
          )}
          <QuoteCardAmountRow title="Taxes d'assurance annuelle" amount={quote.yearlyTaxes} />

          <QuoteCardSeparator />

          <QuoteCardAmountRow title="Prime annuelle totale" amount={quote.yearlyTotalPremium} />

          <QuoteCardSubtitle text="Montants mensuels" />

          <QuoteCardAmountRow title="Prime de base mensuelle" amount={quote.monthlyBasePremium} />
          <QuoteCardAmountRow title="Taxes d'assurance mensuelles (hors attentat)" amount={quote.monthlyTaxes} />
          {quote.monthlyPartnerManagementPremium && gt(quote.monthlyPartnerManagementPremium, zeroAmount) ? (
            <QuoteCardAmountRow title="Frais de gestion mensuels" amount={quote.monthlyPartnerManagementPremium} />
          ) : null}

          <QuoteCardSeparator />

          {'firstMonthTotalPremium' in quote ? (
            <>
              <QuoteCardAmountRow
                title={
                  <>
                    Taxe d&apos;assurance <em>du premier mois (yc attentat)</em>
                  </>
                }
                amount={addAmounts(quote.monthlyTaxes, quote.terrorismTax)}
              />
              {quote.partnerApplicationPremium && gt(quote.partnerApplicationPremium, zeroAmount) ? (
                <QuoteCardAmountRow
                  title={
                    <>
                      Frais de dossier <em>du premier mois</em>
                    </>
                  }
                  amount={quote.partnerApplicationPremium}
                />
              ) : undefined}
              <QuoteCardAmountRow
                title={
                  <>
                    Prime totale <em>du premier mois</em>
                  </>
                }
                amount={quote.firstMonthTotalPremium}
              />
              <QuoteCardSeparator />
              <QuoteCardAmountRow title="Prime totale des mois suivants" amount={quote.subsequentMonthsTotalPremium} />
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const cardSx: SxProps = {
  display: 'flex',
  padding: spacing[50],
  gap: spacing[50],
  backgroundColor: alertRoleConfigurations.informational.backgroundColor,
  border: `1px solid ${alertRoleConfigurations.informational.borderColor}`,
  borderRadius: borderRadius[20],
}

type QuoteCardSubtitleProps = {
  text: string
}

function QuoteCardSubtitle({ text }: QuoteCardSubtitleProps): JSX.Element {
  return (
    <Typography variant="body2Semibold" sx={{ gridColumn: 'span 2', marginTop: spacing[60] }}>
      {text}
    </Typography>
  )
}

type QuoteCardAmountRowProps = {
  title: string | ReactNode
  prefix?: string
  amount: Amount
}

function QuoteCardAmountRow({ title, amount, prefix }: QuoteCardAmountRowProps): JSX.Element {
  return (
    <>
      <Typography
        variant="body2"
        sx={{
          '& > em': {
            fontStyle: 'inherit',
            color: alertRoleConfigurations.informational.secondaryColor,
          },
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2Medium"
        sx={{
          textAlign: 'right',
        }}
      >
        <AmountText amount={amount} displayDecimals prefix={prefix} />
      </Typography>
    </>
  )
}

function QuoteCardSeparator(): JSX.Element {
  return (
    <Box
      component="hr"
      sx={{
        gridColumn: 'span 2',
        height: '1px',
        backgroundColor: colors.blue[200],
        margin: 0,
        padding: 0,
        border: 0,
      }}
    />
  )
}
