import TextField from '@mui/material/TextField'
import { addAmounts, amountToString, gte, lt, newAmount, type Amount } from '@orus.eu/amount'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import {
  PARIS,
  calendarDateToDateTime,
  getCalendarDateFromTimestamp,
  getZonedDateTimeFromMillis,
  type CalendarDate,
} from '@orus.eu/calendar-date'
import {
  AmountText,
  Button,
  Card,
  ContentContainerBackoffice,
  DatepickerFormField,
  FlexSpacedColumn,
  FlexSpacedRow,
  Text,
  borderRadius,
  colorTokens,
  colors,
  spacing,
  useAsyncCallback,
} from '@orus.eu/pharaoh'
import { useNavigate, useParams } from '@tanstack/react-router'
import { useState } from 'react'
import { v4 } from 'uuid'
import { getApplicableQuotes, type TimeRangeQuote } from '../../../../lib/contract-util'
import { formatDateTime } from '../../../../lib/format'

import { css } from '@emotion/react'
import type { CreateInvoicePayload } from '@orus.eu/backend/src/routers/invoices'
import type { InvoicingItem } from '@orus.eu/backend/src/views/invoicing/invoice-payment-status-view'
import { TechnicalError, checkDefinedAndNotNull } from '@orus.eu/error'
import { trpc, trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { validateAmount } from '../../../../lib/validation'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { SelectField } from '../../../molecules/select-field'
import { AmountFormatter, ValidatedTextField } from '../../../molecules/validated-text-field'
import { BackofficeQuoteCard } from '../../../organisms/backoffice-quote-card'
import { InvoiceList, type DraftPreviewRowProps } from '../../../organisms/invoicing/invoice-list'
import { DraftInvoiceStatusTag } from '../../../organisms/invoicing/invoicing-item-status-tag'
import { WithLabel } from '../../../organisms/with-label'

type InvoiceData = CreateInvoicePayload['initialData']

/**
 * This object contains all the data of the invoice that are currently editable, organized in
 * a way that convenient to edit in a form.
 * When fields are null, it means that the backoffice agent has not yet provided a value.
 */
type InvoiceEditableData = {
  issueTimestamp: number | null
  dueTimestamp: number | null

  // properties for the single and mandatory invoice item below

  // no "type" field, because only type 'coverage' is supported. It will be hardcoded and not editable
  // no "signatureId" field because type it will be inferred from the context and not editable
  periodStartTimestamp: number | null
  periodEndTimestamp: number | null

  premiumWithoutTaxes: Amount | null
  // not an actual field of the invoice but we use it as a proxy to define the insuranceTaxes in a way
  // that avoid the ambiguity of whether or not it contains the terrorism taxes
  insuranceTaxesWithoutTerrorismTax: Amount | null

  terrorismTax: Amount | null

  // no insuranceTaxes field because it's computed

  assistanceVAT: Amount | null
}

/**
 * This object lists all the data of the invoice that are not currently editable.
 * This data together with an InvoiceEditableData where no field is null should be enough to
 * create an invoice.
 */
type InvoiceFixedData = {
  signatureId: string
}

const MINIMUM_INVOICE_TOTAL_AMOUNT = newAmount(0.5)

export default function PlatformNewInvoicePage(): JSX.Element {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/new-invoice' })
  assert(subscriptionId, 'This page required a inputSetId param')

  const invoiceEditorData = useInvoiceEditorData(subscriptionId)

  if (invoiceEditorData === 'loading') {
    return <GlobalLoadingState />
  }

  assert(invoiceEditorData !== 'error', 'invoiceEditorData should not have been an error')

  const { invoiceFixedData, customer, invoicingItems, applicableQuotes } = invoiceEditorData

  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Nouvelle facture</BackofficeSectionTitle>

      <NewInvoiceEditor
        fixedData={invoiceFixedData}
        customer={customer}
        invoicingItems={invoicingItems}
        applicableQuotes={applicableQuotes}
      />
    </ContentContainerBackoffice>
  )
}

type NewInvoiceFormProps = {
  fixedData: InvoiceFixedData
  customer: CustomerInformation
  invoicingItems: InvoicingItem[]
  applicableQuotes: TimeRangeQuote[]
}

/**
 * The new invoice editor context data and allows creating draft invoice. We will probably refactor
 * this just a bit later to allow edition of existing draft invoices.
 *
 * @param fixedData the required un-editable invoice data
 * @param user the user for which the invoice is created, needed for the preview
 * @param invoicingItems the other invoicing items of the contract, needed for the preview
 * @param applicableQuotes the quotes that apply to this contract at different dates, depending on the endorsements
 * @constructor
 */
function NewInvoiceEditor({ fixedData, customer, invoicingItems, applicableQuotes }: NewInvoiceFormProps): JSX.Element {
  /**
   * We will store here the current state of the form
   */
  const [editedData, setEditedData] = useState<InvoiceEditableData>(editableDataIntialValues)

  // we try to build the invoice (we get null if we don't have enough data)
  const invoiceData = buildInvoice(editedData, fixedData)

  return (
    <>
      {/* The form where the agent types the data */}

      <InvoiceFormFields
        initialData={editableDataIntialValues}
        onDataUpdated={setEditedData}
        applicableQuotes={applicableQuotes}
      />

      {/* The preview which will help the agent not make a mess */}

      <InvoiceGenerationPreview invoiceData={invoiceData} customer={customer} recentInvoicingItems={invoicingItems} />
    </>
  )
}

const editableDataIntialValues: Readonly<InvoiceEditableData> = {
  issueTimestamp: null,
  dueTimestamp: null,
  periodStartTimestamp: null,
  periodEndTimestamp: null,
  premiumWithoutTaxes: null,
  insuranceTaxesWithoutTerrorismTax: null,
  assistanceVAT: null,
  terrorismTax: null,
}

type InvoiceFormFieldsProps = {
  /**
   * The initial content to initialize the form with
   */
  initialData: Readonly<InvoiceEditableData>
  /**
   * Called by the form when the user updates a value. The whole updated
   * InvoiceEditableData object is sent, with :
   *   - null for invalid or missing fields
   *   - not null for valid values
   * @param invoiceData
   */
  onDataUpdated: (invoiceData: Readonly<InvoiceEditableData>) => void
  applicableQuotes: TimeRangeQuote[]
}

/**
 * This form allows editing everything that can be edited in a draft invoice.
 */
export function InvoiceFormFields(props: InvoiceFormFieldsProps): JSX.Element {
  const { initialData, onDataUpdated, applicableQuotes } = props
  assert(applicableQuotes.length > 0, 'InvoiceFormFields requires at least one applicable quote to work')

  // we need a state variable for each field

  const [issueTimestamp, setIssueTimestamp] = useState(initialData.issueTimestamp)
  const [dueTimestamp, setDueTimestamp] = useState(initialData.dueTimestamp)
  const [periodStartTimestamp, setPeriodStartTimestamp] = useState(initialData.periodStartTimestamp)
  const [periodEndTimestamp, setPeriodEndTimestamp] = useState(initialData.periodEndTimestamp)
  const [premiumWithoutTaxes, setPremiumWithoutTaxes] = useState(initialData.premiumWithoutTaxes)
  const [insuranceTaxesWithoutTerrorismTax, setInsuranceTaxesWithoutTerrorismTax] = useState(
    initialData.insuranceTaxesWithoutTerrorismTax,
  )
  const [assistanceVAT, setAssistanceVAT] = useState(initialData.assistanceVAT)
  const [terrorismTax, setTerrorismTax] = useState(initialData.terrorismTax)

  // the model contains timestamps, but we edit them with datepickers and the time is always 00:00
  // the 4 variables below do the conversions

  const issueCalendarDate = issueTimestamp ? getCalendarDateFromTimestamp(issueTimestamp, PARIS) : null
  const dueCalendarDate = dueTimestamp ? getCalendarDateFromTimestamp(dueTimestamp, PARIS) : null
  const firstPeriodDayCalendarDate = periodStartTimestamp
    ? getCalendarDateFromTimestamp(periodStartTimestamp, PARIS)
    : null
  const lastPeriodDayCalendarDate = periodEndTimestamp
    ? getCalendarDateFromTimestamp(
        getZonedDateTimeFromMillis(periodEndTimestamp, PARIS).minus({ day: 1 }).toMillis(),
        PARIS,
      )
    : null

  // applicableQuote currently selected in the quote information card

  const [applicableQuote, setApplicableQuote] = useState(applicableQuotes[applicableQuotes.length - 1])

  // We must ensure (pre tax + all the taxes = post tax)
  // To prevent mistakes, we remove one degree of freedom and compute the pre-tax value from all the others
  // It's displayed in the form in case the agent needs it

  const insuranceTaxes =
    insuranceTaxesWithoutTerrorismTax && terrorismTax
      ? computeInsuranceTaxes({ insuranceTaxesWithoutTerrorismTax, terrorismTax })
      : null

  const totalPremium =
    premiumWithoutTaxes && insuranceTaxes && assistanceVAT
      ? computeTotalPremium({ premiumWithoutTaxes, insuranceTaxes, assistanceVAT })
      : null

  const isTotalPremiumValid = totalPremium ? gte(totalPremium, MINIMUM_INVOICE_TOTAL_AMOUNT) : true

  const currentData: InvoiceEditableData = {
    issueTimestamp,
    dueTimestamp,
    periodStartTimestamp,
    periodEndTimestamp,
    premiumWithoutTaxes,
    insuranceTaxesWithoutTerrorismTax,
    assistanceVAT,
    terrorismTax,
  }

  // All the handlers below do the same things :
  //   - Take date from the control
  //   - Update the form state
  //   - Update the callback from the parent
  // The handlers for the timestamps also do the conversion from date to timestamp, like
  // for 4 local variables met earlier, but the other way around

  const handleIssueCalendarDateChange = (value: CalendarDate | null) => {
    const newIssueTimestamp = value ? calendarDateToDateTime(value, PARIS).toMillis() : null
    setIssueTimestamp(newIssueTimestamp)
    onDataUpdated({ ...currentData, issueTimestamp: newIssueTimestamp })
  }
  const handleDueCalendarDateChange = (value: CalendarDate | null) => {
    const newDueTimestamp = value ? calendarDateToDateTime(value, PARIS).toMillis() : null
    setDueTimestamp(newDueTimestamp)
    onDataUpdated({ ...currentData, dueTimestamp: newDueTimestamp })
  }
  const handleFirstPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodStartTimestamp = value ? calendarDateToDateTime(value, PARIS).toMillis() : null
    setPeriodStartTimestamp(newPeriodStartTimestamp)
    onDataUpdated({ ...currentData, periodStartTimestamp: newPeriodStartTimestamp })
  }
  const handleLastPeriodDayCalendarDateChange = (value: CalendarDate | null) => {
    const newPeriodEndTimestamp = value ? calendarDateToDateTime(value, PARIS).plus({ day: 1 }).toMillis() : null
    setPeriodEndTimestamp(newPeriodEndTimestamp)
    onDataUpdated({ ...currentData, periodEndTimestamp: newPeriodEndTimestamp })
  }
  const handlePremiumWithoutTaxesChange = (value: Amount | null) => {
    setPremiumWithoutTaxes(value)
    onDataUpdated({ ...currentData, premiumWithoutTaxes: value })
  }
  const handleInsuranceTaxesWithoutTerrorismTaxChange = (value: Amount | null) => {
    setInsuranceTaxesWithoutTerrorismTax(value)
    onDataUpdated({ ...currentData, insuranceTaxesWithoutTerrorismTax: value })
  }
  const handleAssistanceVATChange = (value: Amount | null) => {
    setAssistanceVAT(value)
    onDataUpdated({ ...currentData, assistanceVAT: value })
  }
  const handleTerrorismTaxChange = (value: Amount | null) => {
    setTerrorismTax(value)
    onDataUpdated({ ...currentData, terrorismTax: value })
  }

  return (
    <>
      <Card
        fullwidth
        withBorder
        title="Période de facturation"
        subtitle="La période de couverture dont cette facture fait l’objet"
        avatar={null}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${spacing[60]};
            width: ${fieldMaxWidth};
          `}
        >
          <DatepickerFormField
            label="Premier jour"
            onChange={handleFirstPeriodDayCalendarDateChange}
            value={firstPeriodDayCalendarDate}
            size="small"
          />
          <DatepickerFormField
            label="Dernier jour (inclus)"
            value={lastPeriodDayCalendarDate}
            onChange={handleLastPeriodDayCalendarDateChange}
            size="small"
          />
          <DatepickerFormField
            label="Date limite de paiement"
            infoTooltip={dueDateHint}
            value={dueCalendarDate}
            onChange={handleDueCalendarDateChange}
            size="small"
          />
          <DatepickerFormField
            label="Date d'émission"
            value={issueCalendarDate}
            onChange={handleIssueCalendarDateChange}
            size="small"
          />
        </div>
      </Card>

      <Card fullwidth withBorder title="Montants" subtitle="" avatar={null}>
        <FlexSpacedRow
          margin="0"
          padding="0"
          css={css`
            justify-content: space-between;
          `}
        >
          <FlexSpacedColumn
            margin="0"
            padding="0"
            css={css`
              width: ${fieldMaxWidth};
            `}
          >
            <WithLabel label="Prime de base HT" sx={{ maxWidth: fieldMaxWidth }}>
              <ValidatedTextField
                initialValue={initialData.premiumWithoutTaxes ? amountToString(initialData.premiumWithoutTaxes) : ''}
                validator={validateAmount}
                onChange={handlePremiumWithoutTaxesChange}
                formatter={'amount'}
                size="small"
              />
            </WithLabel>
            <WithLabel label="Taxes d’assurance (attentat non-incluse)" sx={{ maxWidth: fieldMaxWidth }}>
              <ValidatedTextField
                initialValue={
                  initialData.insuranceTaxesWithoutTerrorismTax
                    ? amountToString(initialData.insuranceTaxesWithoutTerrorismTax)
                    : ''
                }
                validator={validateAmount}
                onChange={handleInsuranceTaxesWithoutTerrorismTaxChange}
                formatter={'amount'}
                size="small"
              />
            </WithLabel>
            <WithLabel label="Taxe attentat" sx={{ maxWidth: fieldMaxWidth }}>
              <ValidatedTextField
                initialValue={initialData.terrorismTax ? amountToString(initialData.terrorismTax) : ''}
                validator={validateAmount}
                onChange={handleTerrorismTaxChange}
                formatter={'amount'}
                size="small"
              />
            </WithLabel>

            <WithLabel label="Taxes d’assurance (attentat incluse)" sx={{ maxWidth: fieldMaxWidth }}>
              {/* readonly display of computed amount */}
              <TextField
                value={insuranceTaxes ? amountToString(insuranceTaxes) : ''}
                disabled
                InputProps={{ inputComponent: AmountFormatter }}
                size="small"
              />
            </WithLabel>

            <WithLabel label="TVA assistance" sx={{ maxWidth: fieldMaxWidth }}>
              <ValidatedTextField
                initialValue={initialData.assistanceVAT ? amountToString(initialData.assistanceVAT) : ''}
                validator={validateAmount}
                onChange={handleAssistanceVATChange}
                formatter={'amount'}
                size="small"
              />
            </WithLabel>

            <WithLabel label="Prime totale TTC" sx={{ maxWidth: fieldMaxWidth }}>
              {/* readonly display of computed amount */}
              <TextField
                value={totalPremium ? amountToString(totalPremium) : ''}
                error={!isTotalPremiumValid}
                disabled
                helperText={
                  isTotalPremiumValid
                    ? ''
                    : `Le montant doit être supérieur ou égal à ${amountToString(MINIMUM_INVOICE_TOTAL_AMOUNT, { addCurrency: true })}`
                }
                InputProps={{ inputComponent: AmountFormatter }}
                size="small"
              />
            </WithLabel>
          </FlexSpacedColumn>
          <FlexSpacedColumn margin="0" padding="0">
            {applicableQuotes.length > 1 ? (
              <WithLabel label="Devis du contrat">
                <ApplicableQuoteSelector
                  applicableQuotes={applicableQuotes}
                  selectedQuote={applicableQuote}
                  onQuoteSelected={setApplicableQuote}
                />
              </WithLabel>
            ) : (
              <></>
            )}
            <BackofficeQuoteCard quote={applicableQuote.quote} />
          </FlexSpacedColumn>
        </FlexSpacedRow>
      </Card>
    </>
  )
}

type InvoiceGenerationPreviewProps = {
  invoiceData: InvoiceData | null
  customer: CustomerInformation
  /**
   * Previous invoices to show the preview in context. For now all the invoices but it might change
   * in the future.
   */
  recentInvoicingItems: InvoicingItem[]
}

/**
 * This component displays a preview of the invoice that would be generated or saved.
 * It has two parts to help the agent avoid mistakes :
 *   - A card that focus on the invoice generated : a summary
 *   - The list of invoices of the contract prepended with the invoice that would be generated
 *
 * @param invoiceData the data of the invoice to generate, or null (in this case, the preview is displayed with
 * placeholders and context information)
 * @param user the user for which we generated the invoice
 * @param recentInvoicingItems the other invoicing items of the contract, needed to show the preview in context
 * @constructor
 */
function InvoiceGenerationPreview({
  invoiceData,
  customer,
  recentInvoicingItems,
}: InvoiceGenerationPreviewProps): JSX.Element {
  const draftPreviewProps: DraftPreviewRowProps | null = invoiceData
    ? {
        totalPremium: invoiceData.item.totalPremium,
        dueTimestamp: invoiceData.dueTimestamp,
        periodStartTimestamp: invoiceData.item.periodStartTimestamp,
        periodEndTimestamp: invoiceData.item.periodEndTimestamp,
      }
    : null

  return (
    <Card fullwidth withBorder title="Aperçu" subtitle="" avatar={null}>
      <div>
        {/* Summary on the invoice itself */}

        <Text element="p">
          Tu t&apos;apprêtes à créer une nouvelle facture pour l&apos;utilisateur{' '}
          <strong>{`${customer.firstName} ${customer.lastName} (${customer.email})`}</strong>.
        </Text>
        {invoiceData ? (
          <InvoiceDraftPreviewFromData invoiceData={invoiceData} />
        ) : (
          <Text
            element="p"
            css={css`
              margin-top: ${spacing[60]};
              padding: 50px;
              text-align: center;
              border-radius: ${borderRadius[40]};
              border: 1px solid ${colorTokens['color-stroke-base']};
              color: ${colors.gray[200]};
            `}
          >
            Un aperçu sera généré une fois tous les champs remplis !
          </Text>
        )}

        {/* Invoice in context with the other invoices of the contract */}

        <Text
          element="p"
          css={css`
            margin-top: ${spacing[60]};
          `}
        >
          Voici ce que cela donnerait avec les autres factures du contrat.
        </Text>
        <InvoiceList
          draftPreviewRow={draftPreviewProps}
          invoicingItems={recentInvoicingItems}
          sx={{
            marginTop: spacing[60],
            boxShadow: 'none',
            border: `1px solid ${colorTokens['color-stroke-base']}`,
          }}
        />

        {/* Call to action to be clicked after checking that everything is correct */}

        <div
          css={css`
            margin-top: ${spacing[60]};
            text-align: right;
          `}
        >
          <SaveDraftButton initialData={invoiceData} />
        </div>
      </div>
    </Card>
  )
}

type SaveDraftButtonProps = {
  /**
   * The data of the invoice to generate, or null if invoice generation is not yet possible
   */
  initialData: InvoiceData | null
}

function SaveDraftButton({ initialData }: SaveDraftButtonProps): JSX.Element {
  const { saveDraft, saveDraftStatus } = useSaveDraft()

  const handleClick = () => {
    if (!initialData) {
      return
    }
    saveDraft(initialData)
  }

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        disabled={!initialData || saveDraftStatus !== 'idle'}
        onClick={handleClick}
        isLoading={saveDraftStatus === 'in-progress'}
      >
        Créer un brouillon
      </Button>
    </>
  )
}

type InvoiceDataPreviewProps = {
  invoiceData: InvoiceData
}

/**
 * Shows a preview of the invoice that would be generated, with the same visual language as the
 * cards on the home page.
 *
 * @param invoiceData
 * @constructor
 */
function InvoiceDraftPreviewFromData({ invoiceData }: InvoiceDataPreviewProps): JSX.Element {
  return (
    <>
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <strong>Statut</strong> : <DraftInvoiceStatusTag />
      </div>
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <strong>Montant</strong> : <AmountText amount={invoiceData.item.totalPremium} displayDecimals />
      </div>
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <strong>Due le</strong> : {formatDateTime(invoiceData.dueTimestamp)}
      </div>
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <strong>Période</strong> : {formatDateTime(invoiceData.item.periodStartTimestamp)} -&gt;{' '}
        {formatDateTime(invoiceData.item.periodEndTimestamp)}
      </div>
    </>
  )
}

/**
 * Utility function to stitch together editable data and fixed data to create an invoice.
 *
 * @return the data for the invoice to generate if all the field values are provided, or null if data is missing
 * @param editedData
 * @param fixedData
 */
function buildInvoice(editedData: InvoiceEditableData, fixedData: InvoiceFixedData): InvoiceData | null {
  const {
    issueTimestamp,
    dueTimestamp,
    periodStartTimestamp,
    periodEndTimestamp,
    premiumWithoutTaxes,
    insuranceTaxesWithoutTerrorismTax,
    terrorismTax,
    assistanceVAT,
  } = editedData

  const { signatureId } = fixedData

  if (
    !issueTimestamp ||
    !dueTimestamp ||
    !periodStartTimestamp ||
    !periodEndTimestamp ||
    !premiumWithoutTaxes ||
    !insuranceTaxesWithoutTerrorismTax ||
    !assistanceVAT ||
    !terrorismTax
  ) {
    return null
  }

  const insuranceTaxes = computeInsuranceTaxes({ insuranceTaxesWithoutTerrorismTax, terrorismTax })
  const totalPremium = computeTotalPremium({ premiumWithoutTaxes, insuranceTaxes, assistanceVAT })

  if (lt(totalPremium, MINIMUM_INVOICE_TOTAL_AMOUNT)) return null

  return {
    issueTimestamp,
    dueTimestamp,
    item: {
      type: 'coverage',
      signatureId,
      periodStartTimestamp,
      periodEndTimestamp,
      premiumWithoutTaxes,
      insuranceTaxes,
      assistanceVAT,
      terrorismTax,
      totalPremium,
    },
  }
}

/**
 * This type lists all that is needed to make the invoice editor work
 */
type InvoiceEditorData = {
  invoiceFixedData: InvoiceFixedData
  customer: CustomerInformation
  invoicingItems: InvoicingItem[]
  /**
   * The quotes applicable to the contract at different time ranges, depending
   * on successive endorsements.
   */
  applicableQuotes: TimeRangeQuote[]
}

/**
 * This hook loads all the data needed to edit an invoice
 *
 * @param subscriptionId
 */
function useInvoiceEditorData(subscriptionId: string): InvoiceEditorData | 'loading' | 'error' {
  // load contract and invoice in parallel

  const [contract] = trpcReact.contracts.getContract.useSuspenseQuery(subscriptionId)
  const [invoicingItems] = trpcReact.invoices.listInvoices.useSuspenseQuery(subscriptionId)
  const [customer] = trpcReact.contracts.getContractCustomer.useSuspenseQuery(subscriptionId)
  const [organizationType] =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useSuspenseQuery(subscriptionId)

  const applicableQuotes = getApplicableQuotes(contract, organizationType)

  const signatureId = checkDefinedAndNotNull(contract.versions.at(-1)).signature.id
  return {
    invoiceFixedData: { signatureId },
    customer,
    invoicingItems,
    applicableQuotes,
  }
}

const dueDateHint =
  'La date limite de paiement est en général la veille du premier jour de la période ' +
  "(on paie à l'avance). C'est la date à laquelle on tentera de payer la facture."

type InsuranceTaxesParams = {
  insuranceTaxesWithoutTerrorismTax: Amount
  terrorismTax: Amount
}

function computeInsuranceTaxes(params: InsuranceTaxesParams): Amount {
  return addAmounts(params.insuranceTaxesWithoutTerrorismTax, params.terrorismTax)
}

type TotalPremiumParams = {
  premiumWithoutTaxes: Amount
  insuranceTaxes: Amount
  assistanceVAT: Amount
}

function computeTotalPremium(params: TotalPremiumParams): Amount {
  return addAmounts(params.premiumWithoutTaxes, params.insuranceTaxes, params.assistanceVAT)
}

type SaveDraftHook = {
  saveDraft: (invoiceData: InvoiceData) => void
  saveDraftStatus: 'idle' | 'in-progress'
}

function useSaveDraft(): SaveDraftHook {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/new-invoice' })
  assert(subscriptionId, 'This page required a inputSetId param')
  // The form is single-use : once we've created the invoice, we need to navigate.
  // creating an idea that will not change is a good way to make sure we don't create multiple
  // invoices accidentally
  const [invoiceId] = useState(v4())
  const navigate = useNavigate()
  const [saveDraftStatus, setSaveDraftStatus] = useState<'idle' | 'in-progress'>('idle')
  const saveDraft = useAsyncCallback(
    async (initialData: InvoiceData) => {
      if (saveDraftStatus !== 'idle') {
        throw new TechnicalError('duplicate call to saveDraft')
      }
      setSaveDraftStatus('in-progress')
      const payload: CreateInvoicePayload = { invoiceId, initialData }

      await trpc.invoices.createInvoice.mutate(payload)
      alert('La facture a bien été créée, tu vas maintenant retourner sur la page du contrat.')
      void navigate({ to: '/bak/contracts/$subscriptionId', params: { subscriptionId }, replace: true })
    },
    [subscriptionId, invoiceId, saveDraftStatus, setSaveDraftStatus, navigate],
  )
  return { saveDraft, saveDraftStatus }
}

const fieldMaxWidth = '300px'

type ApplicableQuoteSelectorProps = {
  applicableQuotes: TimeRangeQuote[]
  selectedQuote: TimeRangeQuote
  onQuoteSelected: (value: TimeRangeQuote) => void
}

function ApplicableQuoteSelector(props: ApplicableQuoteSelectorProps): JSX.Element {
  const selectedQuoteId = props.selectedQuote.id

  const availableQuoteIds = props.applicableQuotes.map((applicableQuote) => applicableQuote.id)

  const applicableQuoteLabels = Object.fromEntries(
    props.applicableQuotes.map((applicableQuote) => [applicableQuote.id, getTimeRangeQuoteLabel(applicableQuote)]),
  )

  const handleChange = (value: string) => {
    for (const applicableQuote of props.applicableQuotes) {
      if (applicableQuote.id === value) {
        props.onQuoteSelected(applicableQuote)
        return
      }
    }
    throw new TechnicalError(`Unexpected value received from applicable quote selector`, { context: { value } })
  }

  return (
    <SelectField
      value={selectedQuoteId}
      values={availableQuoteIds}
      labels={applicableQuoteLabels}
      onChange={handleChange}
    />
  )
}

function getTimeRangeQuoteLabel(applicableQuote: TimeRangeQuote): string {
  if (applicableQuote.startTimestamp === null) {
    if (applicableQuote.endTimestamp === null) {
      // Not actually used, because we don't display the selector when there is just one value to select
      // from, but we still want to return something for sake of the consistent behavior of the selector independantly
      // from it's context.
      return 'Initial'
    }

    return `Initial - jusqu'au ${formatDateTime(applicableQuote.endTimestamp)}`
  }

  if (applicableQuote.endTimestamp === null) {
    return `Avenant - à partir du ${formatDateTime(applicableQuote.startTimestamp)}`
  }

  return `Avenant - du ${formatDateTime(applicableQuote.startTimestamp)} au ${formatDateTime(
    applicableQuote.endTimestamp,
  )}`
}
